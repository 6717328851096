<template>
  <v-form>
    <v-select
      label="Schadstoffklasse"
      :items="polutionClasses"
      item-text="name"
      item-value="name"
      v-model="selectedPolutionClass"
      v-on:input="emitChangedValue"
    ></v-select>
  </v-form>
</template>

<script>
export default {
  name: "polutionclass-select",
  props: ["value"],
  data() {
    return {
      polutionClasses: [
        {
          name: "EURO6C",
        },
        {
          name: "EURO6D_TEMP",
        },
        {
          name: "EURO6D",
        },
      ],
      selectedPolutionClass: null,
    };
  },
  methods: {
    parseInputValue(val) {
      if (val) {
        this.selectedPolutionClass = val;
      }
    },
    emitChangedValue() {
      if (this.selectedPolutionClass || this.selectedPolutionClass == 0) {
        this.$emit("input", this.selectedPolutionClass);
      }
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
