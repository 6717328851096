<template>
  <div>
    <v-form>
      <v-select
        label="CO2-Effizienzklasse"
        :items="energyEfficiencyLabels"
        v-model="selectedenergyEfficiencyLabel"
        v-on:input="emitChangedValue"
      ></v-select>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "energy-efficiency-label-select",
  props: ["value"],
  data() {
    return {
      energyEfficiencyLabels: [
        "A+++",
        "A++",
        "A+",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
      ],
      selectedenergyEfficiencyLabel: null,
    };
  },
  methods: {
    emitChangedValue() {
      if (this.selectedenergyEfficiencyLabel) {
        this.$emit("input", this.selectedenergyEfficiencyLabel);
      }
    },
    parseInputValue(val) {
      this.selectedenergyEfficiencyLabel = val;
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
