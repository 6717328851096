<template>
  <div>
    <app-bar :title="'Inserats-ID: ' + vehicleId"/>
    <v-container fluid id="tabmenu" class="d-flex flex-row align-center">
      <div>
        <v-tabs v-model="tab">
          <v-tab href="#tab-cardata"> Fahrzeugdaten </v-tab>
          <v-tab href="#tab-leasing"> Leasingdaten </v-tab>
          <v-tab href="#tab-matrix"> Leasingmatrix </v-tab>
          <v-tab href="#tab-carimages"> Bilderservice </v-tab>
          <v-tab href="#tab-ad"> Inserieren </v-tab>
        </v-tabs>
        </div>
        <div class="d-flex flex-row">
          <v-col>
          <v-btn @click="saveVehicle()">
            <v-icon left color="grey">mdi-content-save-outline</v-icon>
            Speichern
          </v-btn>
        </v-col>
          <v-col>
          <v-btn @click="publish()" v-if="vehicleId">
            <v-icon left color="grey">mdi-publish</v-icon>
            Veröffentlichen
          </v-btn>
        </v-col>

        </div>
        <div class="ml-auto">
          <!-- ToDO Status abfragen -->
        Inseratsstatus:<v-btn
        :v-bind="adStatus"
          class="ma-2"
          outlined
          color="primary"
          disabled
          >
        {{ status }} Entwurf
    </v-btn>
        </div>
    </v-container>
    <v-container fluid>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-cardata">
          <v-row class="ma-0">
            <v-col>
              <make-model-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
            </v-col>
          </v-row>      
          <v-row class="ma-0">
            <v-col>
              <condition-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"              
              />
            </v-col>
            <v-col>
              <key-number-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
            </v-col>
            <v-col>
              <fuel-consumption-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
            </v-col>
            <v-col>
              <environment-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
            </v-col>
          </v-row>
          
          <v-row class="ma-0">
            <v-col>
              <equipment-editor v-bind:vehicle="eautosellerVehicle.values" />
            </v-col>
            <v-col>
              <equipment-extra-editor v-bind:vehicle="eautosellerVehicle.values" />
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col>
              <description-editor v-bind:vehicle="eautosellerVehicle.values" />
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="tab-leasing">
            <v-row class="ma-0">
              <v-col>
                <leasing-editor
                  class="fill-height"
                  v-bind:vehicle="eautosellerVehicle.values"
                />
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col>
                <leasing-details-1
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
                />
              </v-col>
              <v-col>
                <leasing-details-2
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
              </v-col>
              <v-col cols="3">
                <ServiceIncludedEditor
                  class="fill-height"
                  v-bind:vehicle="eautosellerVehicle.values"
                />
              </v-col>
              <v-col cols="3">
                <ServiceExtraEditor
                  class="fill-height"
                  v-bind:vehicle="eautosellerVehicle.values"
                />
              </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="tab-matrix">
          <v-row class="ma-0">
            <v-col>
              <leasing-matrix-editor v-bind:value="eautosellerVehicle.values.id_374" v-bind:vehicle="eautosellerVehicle.values" />
            </v-col>
          </v-row> 
        </v-tab-item>


        <v-tab-item value="tab-carimages">
          <v-row>
            <v-col>
              <image-editor v-bind:vehicle="eautosellerVehicle" />
            </v-col>
          </v-row>
        </v-tab-item>

      <v-tab-item value="tab-ad">
        <v-row class="ma-0">
            <v-col>
              <div class="d-flex flex-column">
                <CarDescriptionEditor
                  class="fill-height"
                  v-bind:vehicle="eautosellerVehicle.values"
                />
                <ValidFromTo
                  class="fill-height mt-5"
                  v-bind:vehicle="eautosellerVehicle.values"
                />
              </div>
            </v-col>
            <v-col>
              <div class="d-flex flex-column">
              <HighlightsEditor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
                <AvailabilityEditor
                  class="fill-height mt-5"
                  v-bind:vehicle="eautosellerVehicle.values"
                />
                
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<!--

BACKUP FIELDS


<v-row class="ma-0">
            <v-col>
              <availability-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col>
              <engine-power-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
            </v-col>
            <v-col>
              <appearance-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
            </v-col>

            <DealerIdEditor
                  class="fill-height mt-5"
                  v-bind:vehicle="eautosellerVehicle.values"
                />
          </v-row>-->

          <!--<v-row class="ma-0">
            <v-col cols="12">
              <equipments-editor v-bind:vehicle="eautosellerVehicle.values" />
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-col>
              <new-contract-editor
                class="fill-height"
                v-bind:vehicle="eautosellerVehicle.values"
              />
            </v-col>
          </v-row>
  -->

<script>
import AppBar from "../components/views/AppBar.vue";
import MakeModelEditor from "../components/eautosellerVehicle/editors/MakeModelEditor.vue";
import FuelConsumptionEditor from "../components/eautosellerVehicle/editors/FuelConsumptionEditor.vue";
import ConditionEditor from "../components/eautosellerVehicle/editors/ConditionEditor.vue";
import EnvironmentEditor from "../components/eautosellerVehicle/editors/EnvironmentEditor.vue";
import KeyNumberEditor from "../components/eautosellerVehicle/editors/KeyNumberEditor.vue";
import DescriptionEditor from "../components/eautosellerVehicle/editors/DescriptionEditor.vue";
import EquipmentEditor from "../components/eautosellerVehicle/editors/EquipmentEditor.vue";
import EquipmentExtraEditor from "../components/eautosellerVehicle/editors/EquipmentExtraEditor.vue";
import HighlightsEditor from "../components/eautosellerVehicle/editors/HighlightsEditor.vue";
import LeasingEditor from "../components/eautosellerVehicle/editors/LeasingEditor.vue";
import LeasingDetails1 from "../components/eautosellerVehicle/editors/LeasingDetails1.vue";
import LeasingDetails2 from "../components/eautosellerVehicle/editors/LeasingDetails2.vue";
import ServiceIncludedEditor from "../components/eautosellerVehicle/editors/ServiceIncludedEditor.vue";
import ServiceExtraEditor from "../components/eautosellerVehicle/editors/ServiceExtraEditor.vue";
import ImageEditor from "../components/eautosellerVehicle/editors/ImageEditor.vue";
import LeasingMatrixEditor from "../components/eautosellerVehicle/editors/LeasingMatrixEditor.vue";
import ValidFromTo from "../components/eautosellerVehicle/editors/ValidFromTo.vue";
import AvailabilityEditor from "../components/eautosellerVehicle/editors/AvailabilityEditor.vue";
import CarDescriptionEditor from "../components/eautosellerVehicle/editors/CarDescriptionEditor.vue";
//import ValidationProvider from "../components/eautosellerVehicle/editors/ValidationProvider.vue";



import CarDataServie from "../services/CarDataService";

//import EnginePowerEditor from "../components/eautosellerVehicle/editors/EnginePowerEditor.vue";
//import AppearanceEditor from "../components/eautosellerVehicle/editors/AppearanceEditor.vue";
//import EquipmentsEditor from "../components/eautosellerVehicle/editors/EquipmentsEditor.vue";
//import NewContractEditor from "../components/eautosellerVehicle/editors/NewContractEditor.vue";
//import Validation from "../components/eautosellerVehicle/editors/Validation.vue";
//import DealerIdEditor from "../components/eautosellerVehicle/editors/DealerIdEditor.vue";


export default {
  name: "vehicle-editor",
  // TODO: use types for props
  props: ["vehicleId", "isNew"],
  components: {
    AppBar,
    MakeModelEditor,
    FuelConsumptionEditor,
    ConditionEditor,
    EnvironmentEditor,
    KeyNumberEditor,
    DescriptionEditor,
    EquipmentEditor,
    EquipmentExtraEditor,
    HighlightsEditor,
    LeasingEditor,
    LeasingDetails1,
    LeasingDetails2,
    ServiceIncludedEditor,
    ServiceExtraEditor,
    ImageEditor,
    LeasingMatrixEditor,
    ValidFromTo,
    AvailabilityEditor,
    CarDescriptionEditor,
    //ValidationProvider,
    //EnginePowerEditor,
    //AppearanceEditor,
    //EquipmentsEditor,
    //NewContractEditor,
    //DealerIdEditor,       

  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.loadVehicle();
      },
      { immediate: true }
    );
  },
  methods: {
    saveVehicle() {
      let vm = this;

      if (this.isNew) {
        // Create
        let saveRequest = this.eautosellerVehicle;
        if(!saveRequest.uploadedImages) {
          saveRequest.uploadedImages = [];
        }
        CarDataServie.create(saveRequest)
          .then((res) => {
            vm.$router.push({
              name: "vehicle",
              params: { vehicleId: res.id, isNew: false },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {        
        // Update

        if(!this.eautosellerVehicle.uploadedImages) {
          this.eautosellerVehicle.uploadedImages = [];
        }

        CarDataServie.update(this.eautosellerVehicle).then((res) => {
          console.log("Saved successfully");
          console.log(res);
        });
      }
    },
    publish() {
      CarDataServie.publish(this.vehicleId).then((res) => {
        console.log(res);
      });
    },
    loadVehicle() {
      let vm = this;
      vm.initializeEmpty();

      if (!this.isNew) {
        CarDataServie.getVehicle(this.vehicleId).then((vehicle) => {
          vm.eautosellerVehicle = vehicle;

          for (let i = 0; i < 628; i++) {
            if (
              !Object.prototype.hasOwnProperty.call(
                vehicle.values,
                "id_" + (i + 1)
              )
            ) {
              vm.eautosellerVehicle.values["id_" + (i + 1)] = undefined;
            }
          }

          //this.$forceUpdate();
        });
      }
    },

    initializeEmpty() {
      this.eautosellerVehicle = {
        values: {},
      };

      for (let i = 0; i < 624; i++) {
        this.eautosellerVehicle.values["id_" + (i + 1)] = undefined;
      }
    },
  },
  data() {
    return {
      tab: 'tab-cardata',
      eautosellerVehicle: {
        values: {},
      },
      adStatus: false,
    };
  },
};
</script>
