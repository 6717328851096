<template>
  <v-form>
    <v-select
      label="Getriebe"
      :items="transmissions"
      item-text="name"
      item-value="value"
      v-model.number="selectedTransmissions"
      v-on:input="emitChangedValue"
    ></v-select>
  </v-form>
</template>

<script>
export default {
  name: "transmission-select",
  props: ["value"],
  data() {
    return {
      transmissions: [
        {
          name: "keine Angabe",
          value: 0,
        },
        {
          name: "Schaltgetriebe",
          value: 1,
        },
        {
          name: "Halbautomatik",
          value: 2,
        },
        {
          name: "Automatik",
          value: 3,
        },
      ],
      selectedTransmissions: null,
    };
  },
  methods: {
    parseInputValue(val) {
      if (val) {
        this.selectedTransmissions = parseInt(val);
      }
    },
    emitChangedValue() {
      if (this.selectedTransmissions || this.selectedTransmissions == 0) {
        this.$emit("input", this.selectedTransmissions);
      }
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
