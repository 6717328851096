<template>
  <editor-card title="Antrieb | Leistung | Schlüssel-Nr.">
    <v-row>
      <v-col>
        <v-text-field v-model.number="vehicle.id_42" label="Türen" type="number" placeholder="z.B. 5" required clearable></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model.number="vehicle.id_60" label="Sitze" type="number" placeholder="z.B. 5" required clearable></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
        label="Grundfarbe außen"
        v-model="vehicle.id_397"
        placeholder="z.B. weiß"
        required
        clearable
      ></v-text-field>
      </v-col>
      <v-col>
        <InsideColorSelect v-model.number="vehicle.id_155"></InsideColorSelect>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <transmission-select v-model="vehicle.id_110" />
      </v-col>
      <v-col>
        <v-text-field label="Hubraum" placeholder="z.B. 1498" suffix="ccm" v-model.number="vehicle.id_52" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field          
            label="Motor"
            suffix="KW"
            v-model.number="vehicle.id_5"
            type="number"
            placeholder="z.B. 110"
          />
      </v-col>
      <v-col>
        <v-text-field
            label="Motor"
            suffix="PS"
            v-model.number="powerPs"
            disabled
            type="number"
          />
      </v-col>
    </v-row>
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";
import InsideColorSelect from '../controls/InsideColorSelect.vue'
import TransmissionSelect from "../controls/TransmissionSelect.vue";

const powerConversionUnit = 1.35962;


export default {
  name: "key-number-editor",
  props: ["vehicle"],
  components: { EditorCard, InsideColorSelect, TransmissionSelect },
  computed: {
      powerPs() {
        let powerKw = parseFloat(this.vehicle.id_5);
        return Math.round(powerKw * powerConversionUnit);
      }    
      /*
      powerPs: {

        get() {
          if (this.vehicle.id_5) {
            let powerKw = parseFloat(this.vehicle.id_5);
            return Math.round(powerKw * powerConversionUnit);
          }
          return "";
        },
        set(powerPs) {
          if (powerPs) {
            let ps = parseFloat(powerPs);
            this.vehicle.id_5 = Math.round(ps / powerConversionUnit);
          } else {
            this.vehicle.id_5 = "";
          }
        },
      },*/
    },
};
</script>
