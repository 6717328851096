<template>
  <div>
    <v-form>
      <v-select
        label="Zielgruppe"
        :items="targetGroups"
        item-text="name"
        item-value="value"
        v-model="selectedTargetGroup"
        v-on:input="emitChangedValue"
      ></v-select>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "leasing-target-group-select",
  props: ["value"],
  data() {
    return {
      targetGroups: [
        {
          name: "Privat",
          value: 1,
        },
        {
          name: "Gewerblich",
          value: 2,
        },
        {
          name: "Privat & Gewerbe",
          value: 3,
        },
      ],
      selectedTargetGroup: null,
    };
  },
  methods: {
    emitChangedValue() {
      if (this.selectedTargetGroup) {
        this.$emit("input", this.selectedTargetGroup);
      }
    },
    parseInputValue(val) {
      if (val) {
        this.selectedTargetGroup = parseInt(val);
      }
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
