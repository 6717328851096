<template>
  <editor-card title="Konfiguration">
    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
      <v-row>
        <v-col>
          <v-text-field label="Marke" class="required" v-model="vehicle.id_3"  required :rules="textfieldRules" placeholder="z.B. Ford" clearable></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Modell" class="required" v-model="vehicle.id_4"  required placeholder="z.B. Kuga" clearable></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Karosserie" class="required" v-model="vehicle.id_626"  required placeholder="z.B. Sports Utility Vehicle (5-türig)" clearable></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Ausstattungslinie" class="required" v-model="vehicle.id_627"  required placeholder="z.B. Titanium" clearable></v-text-field>
        </v-col>
      </v-row>
      <v-row class="col-12 pa-0">
        <v-col>
          <v-text-field label="Motor" class="required" v-model="vehicle.id_628" required placeholder="z.B. 1.5 EcoBoost 110kW" clearable></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Außenfarbe" class="required" v-model="vehicle.id_16"  required placeholder="z.B. Blazer Blue" clearable></v-text-field>
        </v-col>
        <v-col>
          <v-text-field label="Sonderausstattung" class="required" v-model="vehicle.id_621"  required placeholder="z.B. Winter-Paket, Lederlenkrad, Dachreling" clearable></v-text-field>
        </v-col>
        <!--<v-col>
         <v-text-field label="Zusatz" v-model="vehicle.id_616"></v-text-field>
        </v-col>-->
        <v-row class="col-3">
          <v-col>
            <v-text-field label="HSN" v-model.number="vehicle.id_114" placeholder="z.B. 8566" type="number" />
          </v-col>
          <v-col>
            <v-text-field label="TSN" v-model="vehicle.id_115" placeholder="z.B. BTI" />
          </v-col>
        </v-row>
      </v-row>
      <v-row>
		<v-col>
		<v-btn
		:disabled="!valid"
		color="success"
		class="mr-4"
		@click="validate"
		>
		Validate
		</v-btn>

		<v-btn
		color="error"
		class="mr-4"
		@click="reset"
		>
		Reset Form
		</v-btn>

		<v-btn
		color="warning"
		@click="resetValidation"
		>
		Reset Validation
		</v-btn>
		</v-col>
	</v-row>
      </v-form>
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";


export default {
  name: "make-model-editor",
  props: ["vehicle"],
  components: { EditorCard },

	data: () => ({
      valid: true,
      name: '',
      textfieldRules: [
        v => !!v || 'Pflichtfeld',
        v => (v && v.length <= 30) || 'Das Feld darf nicht länger als 30 Zeichen sein',
      ],
    }),

    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
    },

};
</script>


