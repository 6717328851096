<template>
  <editor-card title="Leasing Matrix">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn
          v-if="!hasValidLeasingMatrixConfiguration"
            @click="templateDialog = true"
          >
            Leasingmatrix Template anwenden
          </v-btn>
          <v-btn
            v-if="hasValidLeasingMatrixConfiguration"
            @click="openLeasingMatrixDialog(true, false)"
          >
            Laufleistung hinzufügen
          </v-btn>
          <v-btn
            v-if="hasValidLeasingMatrixConfiguration"
            @click="openLeasingMatrixDialog(false, true)"
          >
            Laufzeit hinzufügen
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container fluid>
            <v-row>
              <v-col v-for="(mileage, index) in yearlyMileages" :key="index">
                <v-icon left color="red" v-if="index > 0" @click="removeKilometers(mileage)">mdi-close-circle</v-icon>{{ mileage | formatMileage }}
              </v-col>
            </v-row>

            <v-row v-for="(contractLength, months) of contractLengths" :key="months">
              <v-col>
                <v-icon left color="red"  @click="removeMonths(months)" >mdi-close-circle</v-icon> {{ months + " Monate" }} 
              </v-col>
              <v-col v-for="(_, index) in contractLength" :key="index">
                <v-text-field @change="generateLeasingMatrixString"
                  v-model.number="contractLength[index]"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      
    </v-container>
    <v-dialog v-model="dataDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Leasing Matrix Datenzeilen</span>
        </v-card-title>
        <v-card-text>
          <v-container v-if="this.addKilometers">
            <v-text-field label="Laufleistung" type="number" v-model.number="dialog.kilometers"></v-text-field>
          </v-container>
          <v-container v-if="this.addMonths">
            <v-text-field type="number" label="Laufzeit" v-model.number="dialog.months"></v-text-field>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dataDialog = false"
          >
            Schließen
          </v-btn>
          <v-btn
            @click="extendLeasingMatrix"
          >
            Hinzufügen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";

export default {
  name: "leasing-matrix-editor",
  props: ["vehicle", "value"],
  components: { EditorCard },
  filters: {
    formatMileage: function (value) {
      if (!value) return "";
      return new Intl.NumberFormat("de-DE").format(value) + " km";
    },
  },
  mounted() {
    if(!this.hasValidLeasingMatrixConfiguration) {
      if(this.value) {
        this.vehicle['id_374'] = this.value;    
      }else {
        this.vehicle['id_374'] = this.templates[0].value;
      }
      this.updateLeasingMatrix();
    }

  },  
  data() {
    return {
      yearlyMileages: [],
      contractLengths: {},
      dataDialog: false,
      addKilometers: false,
      addMonths: false,
      hasValidLeasingMatrixConfiguration: false,
      selectedTemplate: '',
      dialog: {
        month: null,
        kilometers: null
      },
      templates: [
        {
          name: 'Standard',
          value:  '0#12#5000|0#12#10000|0#12#15000|0#12#20000|0#12#25000|0#12#30000|0#12#35000|'+
                  '0#18#5000|0#18#10000|0#18#15000|0#18#20000|0#18#25000|0#18#30000|0#18#35000|' +
                  '0#24#5000|0#24#10000|0#24#15000|0#24#20000|0#24#25000|0#24#30000|0#24#35000|' +
                  '0#30#5000|0#30#10000|0#30#15000|0#30#20000|0#30#25000|0#30#30000|0#30#35000|' +
                  '0#36#5000|0#36#10000|0#36#15000|0#36#20000|0#36#25000|0#36#30000|0#36#35000|' +
                  '0#42#5000|0#42#10000|0#42#15000|0#42#20000|0#42#25000|0#42#30000|0#42#35000|' +
                  '0#48#5000|0#48#10000|0#48#15000|0#48#20000|0#48#25000|0#48#30000|0#48#35000|' +
                  '0#54#5000|0#54#10000|0#54#15000|0#54#20000|0#54#25000|0#54#30000|0#54#35000|' +
                  '0#60#5000|0#60#10000|0#60#15000|0#60#20000|0#60#25000|0#60#30000|0#60#35000',
        }
      ]
    };
  },
  methods: {    
    openLeasingMatrixDialog(kilometers, months) {
      this.addKilometers = kilometers;
      this.addMonths = months;

      this.dialog = {
        month: null,
        kilometers: null
      };

      this.dataDialog = true
    },
    updateLeasingMatrix() {
      let vm = this;
      let values = this.vehicle['id_374'];
      let monthList =  [];
      let mileageList = [null];

      this.yearlyMileages = [];
      this.contractLengths = {};
      
      let sortFunc = function(a, b){return a - b}
      
      let transformedValues = [];
      if(values) {
        
        let rows = values.split("|");
        
        for(let i=0; i < rows.length; i++) {

          let columns = rows[i].split("#");

          let rate = Number(columns[0]);
          
          let months = Number(columns[1]);
          let yearlyMilages = Number(columns[2])

          if(monthList.indexOf(months) === -1) {
            monthList.push(months);
          }

          if(mileageList.indexOf(yearlyMilages) === -1) {
            mileageList.push(yearlyMilages);
          }

          transformedValues.push({
            km: yearlyMilages,
            months: months,
            price: rate
          });

        }


        monthList.sort(sortFunc);
        mileageList.sort(sortFunc);

        mileageList.forEach(val => {
          vm.yearlyMileages.push(val);
        });


        monthList.forEach(currentMonths => {
          const values = [];
          let items = transformedValues.filter(x => x.months == currentMonths);

          items.forEach(v => values.push(v.price));

          vm.contractLengths[currentMonths] = values;

        });

        this.hasValidLeasingMatrixConfiguration = Object.keys(this.contractLengths).length;

      }
    },
    removeKilometers(km) {
      let newContractLengths = this.contractLengths;
      let mileages = this.yearlyMileages.filter(x => x != null);
      let index = mileages.indexOf(km);        

      for(let month in newContractLengths)
      {    
        newContractLengths[month].splice(index, 1)
      }      

      this.contractLengths = newContractLengths;
      this.yearlyMileages.splice(index+1, 1);

      this.generateLeasingMatrixString();
      this.updateLeasingMatrix();
    },
    removeMonths(months) {
      let newContractLengths = this.contractLengths;

      delete newContractLengths[months];

      this.contractLengths = newContractLengths;

      this.generateLeasingMatrixString();
      this.updateLeasingMatrix();
    },
    generateLeasingMatrixString() {
      let vm = this;
      let rows = [];
      
      for(let monthValue in this.contractLengths) {
        let month = Number(monthValue);
    
          let realMilages = vm.yearlyMileages.filter(x => x !== null);

          realMilages.forEach((milage, idx) => {            

            var price = vm.contractLengths[month][idx];

            rows.push(price+"#"+month+"#"+milage);
          });

      }

      this.vehicle['id_374'] = rows.join('|');
      
    },
    initializeWithTemplate() {
      if(this.selectedTemplate) {
        this.value = this.selectedTemplate;
        this.vehicle['id_374'] = this.selectedTemplate;
        this.updateLeasingMatrix();
      }

    },
    extendLeasingMatrix() {
      if(this.addKilometers && this.addMonths) {
        this.value = this.vehicle['id_374'] = "0#"+this.dialog.months+"#"+this.dialog.kilometers;
        this.dataDialog = false;
        return;
      }

      if(this.addKilometers) {
        let months = Object.keys(this.contractLengths);
        let val = "";
        for(let i=0; i < months.length; i++) {
          val += "|"+0+"#"+months[i]+'#'+this.dialog.kilometers;
        }
        this.value = this.vehicle['id_374'] += val;
      }

      if(this.addMonths) {
        let mileages = this.yearlyMileages.filter(x => x != null);
        let val = "";
        for(let i=0; i < mileages.length; i++) {
           val += "|0#"+this.dialog.months+'#'+mileages[i];
        }

        this.value = this.vehicle['id_374'] += val;
      }

      this.dataDialog = false;
      
    }
  },
  watch: {
    value: {
      handler: "updateLeasingMatrix"
    },
  },
}

</script>
