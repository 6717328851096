<template>
    <div class="fill-height car-editor">
        <app-bar title="Neue Fahrzeugkonfiguration" />
        
            <v-container fluid>
                <v-row >
                    <v-col align="right">
                        <v-btn color="primary" v-if="selectedEngine" @click="mapData()">Fahrzeug anlegen</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="d-flex child-flex" cols="3">
                        <editor-card title="Fahrzeug">
                            <v-select @change="$event => onMakerSelectionChanged()" v-model="selectedMaker" :items="makers" item-text="label" item-value="id" label="Marke"/>
                            <v-select @change="$event => onModelSelectionChanged()" v-model="selectedModel" :items="models" item-text="label" item-value="id" label="Modell" v-if="selectedMaker"/>
                            <v-select @change="$event => onBodyDesignSelectionChanged()" v-model="selectedBodyDesign" :items="bodyDesigns" item-text="label" item-value="id" label="Karosserie" v-if="selectedModel"/>
                            <v-select @change="$event => onTrimLevelSelectionChanged()" v-model="selectedTrimLevel" :items="trimLevels" item-text="label" item-value="id" label="Ausstattungslinie" v-if="selectedBodyDesign"/>
                            <v-select @change="$event => onEngineSelectionChanged()" v-model="selectedEngine" :items="engines" item-text="label" item-value="id" label="Motor" v-if="selectedTrimLevel"/>
                        </editor-card>
                    </v-col>
                    <v-col class="d-flex child-flex" cols="9" v-if="selectedEngine">
                        <editor-card title="Ausstattung">
                            <v-expansion-panels>
                                <v-expansion-panel v-for="(cat, catName) in extrasByCategory" v-bind:key="catName">
                                    <v-expansion-panel-header>{{cat.category}}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <template v-for="entry in cat.items">
                                            <v-checkbox v-model="selectedExtras" :label="entry.label" v-bind:key="entry.id" :value="entry.id" />
                                        </template>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>        
                        </editor-card>
                  
                    </v-col>
                </v-row>             
            </v-container>
        

    </div>
</template>
        
<script>

import AppBar from "../components/views/AppBar.vue";
import { mapGetters } from "vuex";
import apiClient from '../services/ApiService';
import EditorCard from "../components/eautosellerVehicle/editors/EditorCard.vue";
import CarDataService from "../services/CarDataService";

export default {
    name: "car-editor",
    // TODO: use types for props
    components: {
        AppBar,
        EditorCard
    },    
    mounted() {
        let vm = this;
        apiClient.getMakers().then(r => {
            vm.makers = r;
        });
    },
    computed: {
        ...mapGetters({
            userInfo: "auth/userInfo",
        }),
    },
    methods: {
        onMakerSelectionChanged() {
            let vm = this;

            this.selectedModel = undefined;
            this.selectedBodyDesign = undefined;
            this.selectedTrimLevel = undefined;
            this.selectedEngine = undefined;
            this.selectedExtras = [];

            if(!this.selectedMaker) {
                return;
            }

            apiClient.getModels(this.selectedMaker).then(r => {
                vm.models = r;
            }); 
        },
        onModelSelectionChanged() {
            let vm = this;
            
            this.selectedBodyDesign = undefined;
            this.selectedTrimLevel = undefined;
            this.selectedEngine = undefined;
            this.selectedExtras = [];

            if(!this.selectedModel) {
                return;
            }

            apiClient.getBodyDesigns(this.selectedModel).then(r => {
                vm.bodyDesigns = r;
            }); 
        },
        onBodyDesignSelectionChanged() {
            let vm = this;
            
            this.selectedTrimLevel = undefined;
            this.selectedEngine = undefined;
            this.selectedExtras = [];

            if(!this.selectedBodyDesign) {
                return;
            }

            apiClient.getTrimLevels(this.selectedBodyDesign).then(r => {
                vm.trimLevels = r;
            }); 
        },
        onTrimLevelSelectionChanged() {
            let vm = this;
                        
            this.selectedEngine = undefined;
            this.selectedExtras = [];

            if(!this.selectedTrimLevel) {
                return;
            }

            apiClient.getEngines(this.selectedTrimLevel).then(r => {
                vm.engines = r;
            }); 
        },
        onEngineSelectionChanged() {
            let vm = this;
            
            this.selectedExtras = [];

            if(!this.selectedEngine) {
                return;
            }
            
            apiClient.getStandardEquipments(this.selectedEngine).then(se => {
                vm.equipment = se;
            
                return apiClient.getExtras(this.selectedEngine).then(r => {
                    vm.extras = r;
                    
                    let items = [];
                    
                    r.forEach(element => {
                        let catName = element.category_name;
                        let currentItem;

                        let hasCategory = items.some(x => {
                            if(x.category == catName) {
                                currentItem = x;
                                return true;
                            }

                            return false;
                        });

                        if(!hasCategory) {
                            currentItem = {
                                category: catName,
                                items: []
                            };

                            items.push(currentItem);
                        }

                        currentItem.items.push(element);
                        
                    });

                    vm.extrasByCategory = items;

                }); 
            });
        },

        mapData() {
            let values = {}                      
            let vm = this;
            
            for (let i = 0; i < 624; i++) {
                values["id_" + (i + 1)] = undefined;
            }

            let engine = this.engines.find(x => x.id === this.selectedEngine);
            let bodyDesign = this.bodyDesigns.find(x => x.id === this.selectedBodyDesign)
            let trimLevel = this.trimLevels.find(x => x.id === this.selectedTrimLevel);

            values["id_2"] = engine.technical_data.vehicle_type;
            values["id_3"] = this.getItemById(this.makers, this.selectedMaker);
            values["id_4"] = this.getItemById(this.models, this.selectedModel);
            values["id_5"] = engine.kw;
            values["id_16"] = this.getColors();
            values["id_42"] = parseInt(bodyDesign.door_count);
            values["id_60"] = parseInt(engine.technical_data.seats);
            values["id_98"] = engine.technical_data.wltp_fuel_consumption_combined ? Number(engine.technical_data.wltp_fuel_consumption_combined) : Number(engine.technical_data.fuel_consumption_combined);
            values["id_99"] = Number(engine.technical_data.wltp_co2_level_combined ? engine.technical_data.wltp_co2_level_combined : engine.technical_data.wltp_co2_level_combined_high)
            values["id_109"] = this.getFuelType(engine);
            values["id_110"] = this.getTransmission(engine);
            values["id_114"] = parseInt(engine.technical_data.hsn);
            values["id_115"] = engine.technical_data.tsn;
            values["id_308"] = this.getEmissionStandard(engine);
            values["id_611"] = values["id_614"] =  `${values["id_3"]} ${values["id_4"]} ${trimLevel.label} ${engine.label}`;
            values["id_617"] = `${values["id_3"]} ${values["id_4"]}`;
            values["id_621"] = this.getExtras();
            values["id_622"] = this.getEquipment();
            values["id_626"] = bodyDesign.label;
            values["id_627"] = trimLevel.label;
            values["id_628"] = engine.label;
            values["id_52"] = engine.technical_data.cc ? Number(engine.technical_data.cc ) : 0;

            if(engine.technical_data.fuel_type === 'Elektro') {
                values["id_207"] = engine.technical_data.power_consumption_combined_per_km ? Number(engine.technical_data.power_consumption_combined_per_km) / 10 : 0;
            } 
            
            
            CarDataService.create({values: values, uploadedImages: []}).then(res => {
                this.$router.push({ name: "vehicle", params: { vehicleId: res.id, isNew: false }});
            }).catch(() => {
                vm.$notify({
                    title: "Fehler beim Anlegen des Datensatzes.",
                    text: "Der Datensatz konnte nicht angelegt werden.",
                    type: "error",
                });
            });

        },
        getColors() {
            let colors = []

            this.selectedExtras.forEach(eid => {
                let color = this.extras.find(x => x.id == eid);
                if(color && color.category_name === "Farben") {
                    colors.push(color.label);
                }
            });

            return colors.join(", ");
        },
        getExtras() {
            let vm = this;
            let extraList = [];

            this.selectedExtras.forEach(e => {
                var extra = vm.extras.find(x => x.id === e);

                if(extra.category_name != "Farben") {
                    extraList.push(extra.label);
                }
            });

            return extraList.join(", ");
        },
        getItemById(list, id) {
            let data;

            list.some(x => {
                if(x.id == id) {
                    data = x;
                    return true;
                }

                return false;
            });

            return data ? data.label : '';
        },
        getTransmission(engine) {
            switch(engine.transmission) {
                case "Manuell":
                    return 1;
                case "Halbautomatik":
                    return 2;
                case "Automatik":
                    return 3;
                default:
                    return 0;
            }
        },
        getFuelType(engine) {
            switch (engine.technical_data.fuel_type)
            {
                case "Benzin":
                    return 1;
                case "Diesel":
                    return 2;
                case "Autogas":
                    return 3;
                case "Erdgas":
                    return 4;
                case "Elektro":
                    return 6;
                case "Hybrid":
                    return 7;
                case "Wasserstoff":
                    return 8;
                case "Ethanol":
                    return 9;
                case "Hybrid-Diesel":
                    return 10;
                case "Bi-Fue":
                    return 11;
            }

            return 0;
        },
        getEmissionStandard(engine) {
            switch(engine.technical_data.emission_standard) {
                case "EU6 D":
                    return "EURO6D";
                case "EU6 C":
                    return "EURO6C";
                case "EU6 D Temp":
                    return "EURO6D_TEMP";                    
                default:
                    return "";
            }
        },
        getEquipment() {
            let equipmentList = this.equipment.map(x => x.label);

            return equipmentList.join(", ");
        }
    },
    data() {
        return {
            makers: [],
            models: [],
            bodyDesigns: [],
            trimLevels: [],
            engines: [],
            extras: [],
            colors: [],
            extrasByCategory: {},
            equipment: [],        

            selectedMaker: undefined,
            selectedModel: undefined,
            selectedBodyDesign: undefined,
            selectedTrimLevel: undefined,
            selectedEngine: undefined,
            selectedExtras: []
        }
    }
};
</script>

<style>
.car-editor .v-messages {
    display: none!important;
}

.car-editor .v-input--checkbox {
    margin-top: 0px;
}
</style>