<template>
  <v-container fluid>
    <editor-card title="Verwendete Bilder">
      <v-container fluid v-if="vehicle.uploadedImages">
        <v-row>
          <v-col
            v-for="image in vehicle.uploadedImages"
            class="d-flex child-flex"
            cols="3"
            :key="image"
          >
            <v-card>
              <v-img
                height="250"
                :src="calculateImageUrl(image)"
                aspect-ratio="1"
                class="grey lighten-2"
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn icon @click="deleteImage(image)">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid
        v-if="!vehicle.uploadedImages || vehicle.uploadedImages.length === 0"
      >
        <p>Keine Bilder vorhanden</p>
      </v-container>

      <v-container fluid>
        <v-file-input
          truncate-length="15"
          accept="image/png, image/jpeg"
          label="Bilder auswählen"
          multiple
          v-model="selectedFiles"
        ></v-file-input>
        <v-btn depressed color="primary" @click="upload()">Upload</v-btn>
      </v-container>
    </editor-card>
    <br />

    <editor-card title="Bildersuche">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-select
              v-model="selectedMaker"
              :items="makers"
              label="Hersteller"
              @change="updateModels"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="selectedModel"
              :items="models"
              label="Modell"
              @change="updateTrimLevels"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              v-model="selectedTrimLevel"
              :items="trimLevels"
              label="Aufbau"
            ></v-select>
          </v-col>
          <v-col>
            <v-btn @click="findByModelMakerAndTrimLevel">Suchen</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <v-row>
          <v-col cols="1">
            <v-text-field label="HSN" v-model="hsn"></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-text-field label="TSN" v-model="tsn"></v-text-field>
          </v-col>
          <v-col>
            <v-btn @click="findByHsnTsn">Suchen</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="imageList && imageList.length > 0">
        <v-row>
          <v-col
            v-for="image in imageList"
            class="d-flex child-flex"
            cols="2"
            :key="image.key"
          >
            <v-card>
              <v-img
                height="250"
                :src="image.publicUrl"
                aspect-ratio="1"
                class="grey lighten-2"
              ></v-img>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn icon @click="addImage(image.key)">
                  <v-icon>mdi-plus-box</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </editor-card>
  </v-container>
</template>

<script>
const imageUrl = process.env.VUE_APP_CDN_URL;
import EditorCard from "./EditorCard.vue";
import carDataService from "./../../../services/CarDataService";
import carImageService from "../../../services/ImageService";

export default {
  name: "image-editor",
  props: ["vehicle"],
  components: { EditorCard },
  created() {
    let vm = this;
    carImageService.getMakers().then((res) => {
      vm.makers = res;
    });
  },
  computed: {
    rowCount() {
      return this.vehicle.uploadedImages
        ? this.vehicle.uploadedImages.length
        : 0;
    },
    imageCount() {
      return this.vehicle.uploadedImages
        ? this.vehicle.uploadedImages.length
        : 0;
    },
  },
  methods: {
    addImage(key) {
      const vm = this;
      carDataService.addImage(this.vehicle.id, key).then(res => {
        if (res.success) {
            vm.vehicle.uploadedImages = res.images;
            this.selectedFiles = [];
          }
      });
    },
    findByHsnTsn() {
      let vm = this;
      carImageService.getByHsnTsn(this.hsn, this.tsn).then((res) => {
        vm.imageList = res;
      });
    },
    findByModelMakerAndTrimLevel() {
      let vm = this;
      carImageService
        .getByMakerModelTrimLevel(
          this.selectedMaker,
          this.selectedModel,
          this.selectedTrimLevel
        )
        .then((res) => {
          vm.imageList = res;
        });
    },
    updateModels() {
      let vm = this;
      carImageService.getModels(this.selectedMaker).then((res) => {
        vm.models = res;
      });
    },
    updateTrimLevels() {
      let vm = this;
      carImageService
        .getTrimLevels(this.selectedMaker, this.selectedModel)
        .then((res) => {
          vm.trimLevels = res;
        });
    },
    calculateImageUrl(path) {
      return imageUrl + path;
    },
    upload() {
      let vm = this;
      carDataService
        .uploadImages(this.vehicle.id, this.selectedFiles)
        .then((res) => {
          if (res.success) {
            vm.vehicle.uploadedImages = res.images;
            this.selectedFiles = [];
          }
        });
    },
    deleteImage(image) {
      let vm = this;

      this.$dialog
        .confirm({
          text: "Möchten Sie das Bild wirklich löschen?",
          title: "Bild löschen",
          actions: {
            false: "Nein",
            true: "Ja",
          },
        })
        .then((res) => {
          if (res) {
            carDataService.deleteImage(this.vehicle.id, image).then((res) => {
              if (res.success) {
                vm.vehicle.uploadedImages = res.images;
              }
            });
          }
        });
    },
  },
  data() {
    return {
      selectedFiles: [],
      makers: [],
      selectedMaker: "",
      models: [],
      selectedModel: "",
      trimLevels: [],
      selectedTrimLevel: "",
      hsn: "",
      tsn: "",
      imageList: [],
    };
  },
};
</script>
