<template>
  <editor-card title="Leasingdetails 2">
    <v-row>
      <v-col>
        <v-text-field
          label="Laufzeit"
          type="number"
          min="0"
          suffix="Monate"
          v-model.number="vehicle.id_294"
          placeholder="z.B. 36"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Anzahlung"
          type="number"
          min="0"
          suffix="€"
          v-model.number="vehicle.id_295"
          placeholder="z.B. 0"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
    <v-text-field
      label="p.a."
      type="number"
      min="0"
      suffix="km"
      readonly="readonly"
      v-model.number="kmPa"
      placeholder="z.B. 10000"
    ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        label="Gesamt"
        type="number"
        min="0"
        suffix="km"
        v-model.number="vehicle.id_298"
        placeholder="z.B. 30000"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-text-field
        label="Bruttorate"
        type="number"
        min="0"
        suffix="€"
        v-model.number="vehicle.id_293"
        v-on:input="onGrossRateChanged"
        placeholder="z.B. 119"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        label="Nettorate"
        type="number"
        min="0"
        suffix="€"
        v-model="vehicle.id_613"
        readonly="readonly"
        placeholder="z.B. 100"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-text-field
        label="Mehrkilometer"
        type="number"
        min="0"
        suffix="ct"
        v-model.number="vehicle.id_300"
        placeholder="z.B. 11,37"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        label="Minderkilometer"
        type="number"
        min="0"
        suffix="ct"
        v-model.number="vehicle.id_301"
        placeholder="z.B. 5,31"
      ></v-text-field>
    </v-col>
  </v-row>
    <v-row>
      <v-col>
        <v-text-field
        label="Nominaler Zinssatz"
        type="number"
        min="0.0"
        suffix="%"
        v-model.number="vehicle.id_303"
        placeholder="z.B. 2,7"
      ></v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        label="Effektiver Jahreszins"
        type="number"
        min="0.0"
        suffix="%"
        v-model.number="vehicle.id_292"
        placeholder="z.B. 2,8"
      ></v-text-field>
    </v-col>
  </v-row>

    <!--<v-checkbox label="verhandelbar (TODO: Welches Feld?)"></v-checkbox>
    <v-text-field
      label="Leasinggesamtbetrag"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_306"
    ></v-text-field>
    <v-text-field
      label="Nettokreditbetrag"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_307"
    ></v-text-field>
    <v-text-field
      label="Restwert nach Ende (inkl. MwSt.)"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_299"
    ></v-text-field>
    <v-text-field
      label="Überführungskosten (brutto)"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_107"
    ></v-text-field>
    
    <v-text-field
      label="Leasingsonderkonditionen(Prämien)"
      v-model="vehicle.id_346"
    ></v-text-field>
    <span
      >Trenner ist | 1=Inzahlungnahmebonus 2=Umweltbonus 3=Umweltprämie
      4=Eroberungsprämie 5=Behindertenrabatt 6=Behördenrabatt 7=BW eGutschein
      8=NRW Bonus 9=Berlin Bonus</span
    >
    <v-checkbox
      label="Überführung enthalten?"
      v-model.number="vehicle.id_347"
      :false-value="0"
      :true-value="1"
    />
    <v-checkbox
      label="Zulassung enthalten?"
      v-model.number="vehicle.id_348"
      :false-value="0"
      :true-value="1"
    />
    <v-checkbox
      label="KFZ-Lieferung enthalten?"
      v-model.number="vehicle.id_349"
      :false-value="0"
      :true-value="1"
    />
    <v-checkbox
      label="Wartung enthalten?"
      v-model.number="vehicle.id_370"
      :false-value="0"
      :true-value="1"
    />
    <v-checkbox
      label="Verschleiss enthalten?"
      v-model.number="vehicle.id_371"
      :false-value="0"
      :true-value="1"
    />
    <v-text-field
      label="Wartung / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_376"
    ></v-text-field>
    <v-text-field
      label="Verschleiss / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_377"
    ></v-text-field>
    <v-text-field
      label="Wartung / Verschleiss / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_378"
    ></v-text-field>
    <v-text-field
      label="Haftpflicht / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_379"
    ></v-text-field>
    <v-text-field
      label="Haftpflicht SB"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_380"
    ></v-text-field>
    <v-text-field
      label="Vollkasko / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_381"
    ></v-text-field>
    <v-text-field
      label="Vollkasko SB"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_382"
    ></v-text-field>
    <v-text-field
      label="KFZ-Lieferung einmalig"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_383"
    ></v-text-field>
    <v-text-field
      label="KFZ-Steuer / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_384"
    ></v-text-field>
    <v-text-field
      label="8-fach Bereifung / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_385"
    ></v-text-field>
    <v-text-field
      label="HU-AU / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_386"
    ></v-text-field>
    <v-text-field
      label="Rückgabeversicherung / Monat"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_387"
    ></v-text-field>
    <v-text-field
      label="Zulassungskosten einmalig"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_388"
    ></v-text-field>
    <v-text-field
      label="Umweltbonus BAFA"
      type="number"
      min="0"
      suffix="€"
      v-model.number="vehicle.id_390"
    ></v-text-field>-->
  </editor-card>
</template>

<script>
import EditorCard from "./EditorCard.vue";

export default {
  name: "LeasingDetails2",
  props: ["vehicle"],
  components: { EditorCard },
  mounted() {
    if(this.vehicle.id_293 && this.vehicle.id_293 > 0) {
        this.vehicle.id_613 = this.calcNet();
      } 
  },
  methods: {
    onGrossRateChanged() {
      if(this.vehicle.id_293 && this.vehicle.id_293 > 0) {        
        this.vehicle.id_613 = this.calcNet();
      } 
    },
    calcNet() {
      if(this.vehicle.id_293 && this.vehicle.id_293 > 0) {
        let val = this.vehicle.id_293 / 1.19;
        return val.toFixed(2);
      } 
      return 0;
    }    
  },
  computed: {
    kmPa() {
      if(this.vehicle.id_298 && this.vehicle.id_294) {
        return this.vehicle.id_298 / (this.vehicle.id_294  / 12);
      }
      return 0;
    }
  }
};
</script>
