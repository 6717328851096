<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation>
      <v-container>
        <v-row>
          <v-col class="ps-0 pb-0 pt-3">Erstzulassung:</v-col>
        </v-row>
        <v-row class="pt-0">
          <v-col class="ps-0 pt-0">
            <v-select class="pt-0"
              label="Tag"
              :items="days"
              v-model.number="selectedDay"
              v-on:input="emitChangedValue"
            ></v-select>
          </v-col>
          <v-col class="ps-0 pt-0">
            <v-select class="pt-0"
              label="Monat"
              :items="months"
              v-model.number="selectedMonth"
              v-on:input="emitChangedValue"
            ></v-select>
          </v-col>
          <v-col class="ps-0 pt-0">
            <v-select class="pt-0"
              label="Jahr"
              :items="years"
              v-model.number="selectedYear"
              v-on:input="emitChangedValue"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "first-registration-select",
  props: ["value"],
  data() {
    // TODO: Weitere Werte für Tag, Monat und Jahr generieren
    return {
      valid: true,
      days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],      
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
    };
  },
  computed: {
    years() {
      let now = new Date();
      let currentYear = now.getFullYear();
      let years = [];
      for(let i=0; i<20;i++) {
        years.push(currentYear-i);
      }

      return years;
    }
  },
  methods: {
    
    emitChangedValue() {
      let firstRegistrationString = null;

      if (this.selectedMonth && this.selectedYear) {
        if (this.selectedDay) {
          firstRegistrationString = `${this.convertToString(
            this.selectedDay
          )}.${this.convertToString(this.selectedMonth)}.${this.convertToString(
            this.selectedYear
          )}`;
        } else {
          firstRegistrationString = `${this.convertToString(
            this.selectedMonth
          )}.${this.convertToString(this.selectedYear)}`;
        }

        this.$emit("input", firstRegistrationString);
      }
    },
    convertToString(number) {
      return number.toString().padStart(2, "0");
    },
    parseInputValue(val) {
      if (val) {
        const regEx = /((?<day>\d{2}).)?(?<month>\d{2}).(?<year>\d{4})/;
        let match = val.match(regEx);
        if (match) {
          if (match.groups.day) {
            this.selectedDay = parseInt(match.groups.day);
          }

          this.selectedMonth = parseInt(match.groups.month);
          this.selectedYear = parseInt(match.groups.year);
        }
      } else {
        this.selectedMonth = null;
        this.selectedYear = null;
      }
    },
  },
  watch: {
    value: {
      handler: "parseInputValue",
      immediate: true,
    },
  },
};
</script>
