<template>
<div class="fill-height">
    <app-bar title="Neue Fahrzeugkonfiguration" />
    <iframe :src="'/configurator.html#l!/start/user/'+userInfo.id"></iframe>    
</div>
</template>

<script>

import AppBar from "../components/views/AppBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "vehicle-configurator",
  // TODO: use types for props
  components: {
    AppBar
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      userInfo: "auth/userInfo",
    }),
  }
};
</script>

<style >
iframe {
    height: calc(100% - 71px);
    width: 100%;
}
</style>